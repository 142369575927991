import React from 'react';
import '../App.css';
import Checkbox from 'react-simple-checkbox';
import ReactSlider from "react-slider";

class Menu extends React.Component {

    render() {
        let visibility = "hide"
        if (this.props.open) {
            visibility = "show"
        }

        return (
            <div id="Menu" className={visibility}>
                <div id="MenuHeader">
                    <span>theQuestions</span>
                    <button id="CloseMenuBtn" onClick={this.props.onClickClose}>×</button>
                </div>
                <div className="CategoryItem">
                    <Checkbox
                        className={"Checkbox"}
                        size={2}
                        checked={this.props.showParty}
                        onChange={this.props.toggleParty}
                        tickAnimationDuration={200}
                        backAnimationDuration={100}
                    />
                    <span className={"CheckboxLabel"}>party icebreakers</span>
                </div>
                <div className="CategoryItem">
                    <Checkbox
                        className={"Checkbox"}
                        size={2}
                        checked={this.props.showProfessional}
                        onChange={this.props.toggleProfessional}
                        tickAnimationDuration={200}
                        backAnimationDuration={100}
                    />
                    <span className={"CheckboxLabel"}>professional icebreakers</span>
                </div>
                <div className="CategoryItem">
                    <Checkbox
                        className={"Checkbox"}
                        size={2}
                        checked={this.props.showFirestarters}
                        onChange={this.props.toggleFirestarters}
                        tickAnimationDuration={200}
                        backAnimationDuration={100}
                    />
                    <span className={"CheckboxLabel"}>firestarters</span>
                </div>
                <span id="ThemesHeader">themes</span>
                <button className="ThemeButton" onClick={this.props.setTheme.bind(this, "Memphis")}>
                    memphis
                </button>
                <button className="ThemeButton" onClick={this.props.setTheme.bind(this, "Herringbone")}>
                    herringbone
                </button>
                <button className="ThemeButton" onClick={this.props.setTheme.bind(this, "Prism")}>
                    prism
                </button>
                <button className="ThemeButton" onClick={this.props.setTheme.bind(this, "Zen")}>
                    zen
                </button>
                <span id="SettingsHeader">settings</span>
                <span className="Setting">transition time</span>
                <ReactSlider
                    defaultValue={50}
                    min={2}
                    className="Slider"
                    thumbClassName="SliderThumb"
                    trackClassName="SliderTrack"
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    onAfterChange={this.props.changeTransitionSpeed}
                />
                <div id="Credit">
                    <span>{"Created by "}</span>
                    <a href={"http://www.mattrutherford.co.uk"}>Matt Rutherford</a>
                    <br />
                    <span>{"Developed by Manu Rutherford"}</span>
                </div>
            </div>
        )
    }
}

export default Menu