import React from 'react';
import './App.css';
import './Themes.css'
import Menu from "./components/Menu";
import Panel from "./components/Panel";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      theme: "Zen",
      showParty: true,
      showProfessional: true,
      showFirestarters: true,
      transitionSpeed: 1,
    };

    this.menuClick = this.menuClick.bind(this);
    this.toggleParty = this.toggleParty.bind(this);
    this.toggleProfessional = this.toggleProfessional.bind(this);
    this.toggleFirestarters = this.toggleFirestarters.bind(this);
    this.setTheme = this.setTheme.bind(this);
    this.changeTransitionSpeed = this.changeTransitionSpeed.bind(this);
  }

  menuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen
    })
  }

  setTheme(name) {
    this.setState({theme: name})
  }

  toggleParty() {
    this.setState({showParty: !this.state.showParty})
  }

  toggleProfessional() {
    this.setState({showProfessional: !this.state.showProfessional})
  }

  toggleFirestarters() {
    this.setState({showFirestarters: !this.state.showFirestarters})
  }

  changeTransitionSpeed(newSpeed) {
    this.setState({transitionSpeed: newSpeed * 0.02})
  }

  render() {
    let menuBtnClass = ""
    if (this.state.menuOpen) {
      menuBtnClass = "fade"
    }

    return (
        <div className={"App " + this.state.theme}>
          <button id="OpenMenuBtn" className={this.state.theme + " " + menuBtnClass} onClick={this.menuClick}>Menu</button>
          <Menu
              open={this.state.menuOpen}
              onClickClose={this.menuClick}
              showParty={this.state.showParty}
              showProfessional={this.state.showProfessional}
              showFirestarters={this.state.showFirestarters}
              toggleParty={this.toggleParty}
              toggleProfessional={this.toggleProfessional}
              toggleFirestarters={this.toggleFirestarters}
              setTheme={this.setTheme}
              changeTransitionSpeed={this.changeTransitionSpeed}
          />
          <Panel
              showParty={this.state.showParty}
              showProfessional={this.state.showProfessional}
              showFirestarters={this.state.showFirestarters}
              theme={this.state.theme}
              transitionSpeed={this.state.transitionSpeed}
          />
        </div>
    )
  }
}

export default App;
