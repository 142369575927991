import React from 'react';
import '../App.css';

class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            question: "No Questions Selected :(",
            allQuestions: [],
            selectedQuestions: [],
            index: 0,
            fadeClass: "",
        }

        this.clickLast = this.clickLast.bind(this);
        this.clickRandom = this.clickRandom.bind(this);
        this.clickNext = this.clickNext.bind(this);
        this.displayNewQuestion = this.displayNewQuestion.bind(this);
        this.fadePanelIn = this.fadePanelIn.bind(this);
    }

    async componentDidMount() {
        await fetch('questions')
            .then(data => data.json())
            .then(questions => this.setState({allQuestions: questions }))
            .catch()

        this.selectQuestions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.showParty !== this.props.showParty)
            || (prevProps.showProfessional !== this.props.showProfessional)
            || (prevProps.showFirestarters !== this.props.showFirestarters)) {
            this.selectQuestions();
        }
    }

    selectQuestions() {
        let selected = [];

        for (let i = 0; i < this.state.allQuestions.length; i++) {
            let question = this.state.allQuestions[i];
            if ((this.props.showParty && question.party)
                || (this.props.showProfessional && question.professional)
                || (this.props.showFirestarters && question.firestarter)) {
                selected.push(question.text)
            }
        }

        this.setState({selectedQuestions: selected});

        if (selected.length > 0) {
            let index = this.getRandomInt(selected.length);
            this.setState({index: index});
            this.setState({question: selected[index]});
        } else {
            this.setState({index: 0})
            this.setState({question: ""})
        }
    }

    getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }

    clickLast() {
        let newIndex = (this.state.index - 1)
        if (newIndex < 0) {
            newIndex = this.state.selectedQuestions.length - 1
        }
        this.setState({index: newIndex});
        this.displayNewQuestion();
    }

    clickRandom() {
        this.setState({index: this.getRandomInt(this.state.selectedQuestions.length)});
        this.displayNewQuestion();
    }

    clickNext() {
        let newIndex = (this.state.index + 1);
        if (newIndex >= (this.state.selectedQuestions.length)) {
            newIndex = 0
        }
        this.setState({index: newIndex});
        this.displayNewQuestion();
    }

    displayNewQuestion() {
        this.setState({fadeClass: "fade"});
        setTimeout(this.fadePanelIn, this.props.transitionSpeed * 1000);
    }

    fadePanelIn() {
        this.setState({
            question: this.state.selectedQuestions[this.state.index],
            fadeClass: ""
        })
    }

    render() {
        const transitionString = "opacity " + this.props.transitionSpeed + "s ease-in-out";
        const panelTransition = {transition: transitionString}

        return (
            <div id="Panel" className={this.state.fadeClass} style={panelTransition}>
                <div id="Question" className={this.props.theme}>{this.state.question}</div>
                <button className={"NavButton " + this.props.theme} onClick={this.clickLast}>Last</button>
                <button className={"NavButton " + this.props.theme} onClick={this.clickRandom}>Random</button>
                <button className={"NavButton " + this.props.theme} onClick={this.clickNext}>Next</button>
            </div>

        )
    }
}

export default Panel;